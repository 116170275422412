import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { paymentsService } from './Service';
import config from '../../../config/config';
import './payment.styles.scss'
import { ReactComponent as Boarding } from '../../../images/boarding.svg'
import Popup from '../../../components/UI/Popup/Popup';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { handleError } from '../../../utils/handleCatch';


const Payment = ({ jwt, userId }) => {
    const [isBoarded, setIsBoarded] = useState(false);
    const [paymenthMethods, setPaymentMethods] = useState(false);
    const MySwal = withReactContent(Swal)
    const profileId = useSelector(state => state.user?.user?.id)
    const { t } = useTranslation();

    console.log('jwt',jwt);
    useEffect(() => {
        if (jwt) {
            paymentsService.paymenthMethods(jwt)
                .then(response => {
                    console.log(response);
                    if (response.data.length > 0) {
                        response.data.forEach(method => {
                            if (method.paymentMethodType === "XPAY") {
                                setIsBoarded(true);
                            }
                        });
                    }
                }).catch((error) => {
                    // handleError(error)
                    console.log(error);
                })
            window.addEventListener("focus", onFocus)
            paymentsService.startBoarding(jwt)
                .then(response => {
                    // Inizializzazione SDK
                    XPay.init();

                    // Oggetto contenente la configurazione del pagamento
                    var configuration = {
                        baseConfig: {
                            apiKey: process.env.REACT_APP_NEXI_APIKEY,
                            enviroment: process.env.REACT_APP_NEXI_ENV === "STAGE" ? XPay.Environments.INTEG :XPay.Environments.PROD
                        },
                        paymentParams: {
                            amount: "0",
                            transactionId: response.transactionCode,
                            currency: response.currency,
                            timeStamp: response.timestamp,
                            mac: response.mac,
                            urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${userId !== null ? userId : profileId}`
                        },
                        customParams: {
                            num_contratto: response.contractNumber,
                            tipo_servizio: 'paga_oc3d',
                            tipo_richiesta: 'PP'
                        },
                        language: XPay.LANGUAGE.ITA,

                    };
                    // Configurazione lightbox
                    XPay.initLightbox(configuration);
                })
                .catch(error => { 
                    console.log(error); 
                    // handleError(error)
                })
            window.addEventListener("XPay_Payment_Result", xpayResult);
        }
    }, [jwt, userId])

    useEffect(() => {
        paymentsService.paymenthMethods(jwt)
            .then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            setIsBoarded(true);
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
                // handleError(error)
            })
        window.addEventListener("focus", onFocus)
        paymentsService.startBoarding(jwt)
            .then(response => {
                // Inizializzazione SDK
                XPay.init();

                // Oggetto contenente la configurazione del pagamento
                var configuration = {
                    baseConfig: {
                        apiKey: process.env.REACT_APP_NEXI_APIKEY,
                        enviroment: process.env.REACT_APP_NEXI_ENV === "STAGE" ? XPay.Environments.INTEG :XPay.Environments.PROD
                    },
                    paymentParams: {
                        amount: "0",
                        transactionId: response.transactionCode,
                        currency: response.currency,
                        timeStamp: response.timestamp,
                        mac: response.mac,
                        urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${userId !== null ? userId : profileId}`
                    },
                    customParams: {
                        num_contratto: response.contractNumber,
                        tipo_servizio: 'paga_oc3d',
                        tipo_richiesta: 'PP'
                    },
                    language: XPay.LANGUAGE.ITA,

                };
                // Configurazione lightbox
                XPay.initLightbox(configuration);
            })
            .catch(error => { 
                console.log(error); 
                // handleError(error)
            })
        window.addEventListener("XPay_Payment_Result", xpayResult);
    }, [])

    const onFocus = () => {
        paymentsService.paymenthMethods(jwt)
            .then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            setIsBoarded(true);
                        }
                    });

                }
            }).catch((error) => {
                console.log(error);
                // handleError(error)
            })
    }

    const xpayResult = () => {
        paymentsService.paymenthMethods(jwt)
            .then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            setIsBoarded(true);
                            MySwal.fire({
                                icon: 'success',
                                title: "Associazione avvenuta correttamente!",
                                showConfirmButton: true,
                                confirmButtonText: "Continua",
                                confirmButtonColor: "#E2001A"
                            })
                        }
                    });

                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: "Qualcosa è andato storto",
                        text: "Vi preghiamo di riprovare più tardi, grazie.",
                        showConfirmButton: true,
                        confirmButtonText: "Continua",
                        confirmButtonColor: "#E2001A"
                    })
                }
            }).catch((error) => {
                console.log(error);
                // handleError(error)
            })
            paymentsService.startBoarding(jwt)
            .then(response => {
                // Inizializzazione SDK
                XPay.init();

                // Oggetto contenente la configurazione del pagamento
                var configuration = {
                    baseConfig: {
                        apiKey: process.env.REACT_APP_NEXI_APIKEY,
                        enviroment: process.env.REACT_APP_NEXI_ENV === "STAGE" ? XPay.Environments.INTEG :XPay.Environments.PROD
                    },
                    paymentParams: {
                        amount: "0",
                        transactionId: response.transactionCode,
                        currency: response.currency,
                        timeStamp: response.timestamp,
                        mac: response.mac,
                        urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${userId !== null ? userId : profileId}`
                    },
                    customParams: {
                        num_contratto: response.contractNumber,
                        tipo_servizio: 'paga_oc3d',
                        tipo_richiesta: 'PP'
                    },
                    language: XPay.LANGUAGE.ITA,

                };
                // Configurazione lightbox
                XPay.initLightbox(configuration);
            })
            .catch(error => { 
                console.log(error); 
                // handleError(error)
            })
    }

    const renderAllocateCard = () => {
        return (
            <Popup
                action={() => XPay.openLightbox()}
                title={t("payment.allocate")}
                content={t("payment.allocate.question")}
                button={t("confirm")}
                trigger={
                    <button className="custom-button">
                        <span>Associa la carta</span>
                    </button>
                }
            />
        )
    }

    const removePaymenMethod = () => {
        paymentsService.removePaymentMethod(jwt)
            .then(response => {
                setPaymentMethods(response.data);
                setIsBoarded(false)
                MySwal.fire({
                    icon: 'success',
                    title: "Rimozione carta avvenuta correttamente!",
                    showConfirmButton: true,
                    confirmButtonText: "Continua",
                    confirmButtonColor: "#E2001A"
                })
            }).catch((error) => {
                console.log(error);
                // handleError(error)
            })
    }

    const renderRemoveCard = () => {
        return (
            <Popup
                action={() => removePaymenMethod()}
                title={t("payment.remove.title")}
                content={t("payment.remove.question")}
                button={t("payment.remove")}
                trigger={
                    <button className="custom-button">
                        <span>Rimuovi la carta</span>
                    </button>
                }
            />
        )
    }

    return (
        <div className='payments'>
            <label className='title'>{t("profileContainer.payments")}</label>
            <div className='image-container'>
                <Boarding />
            </div>
            <label className='text'>Le modalità di pagamento sotto indicate permettono di effettuare pagamenti online solo sul nostro sito e attraverso l'APP mobile. Questa procedura richiederà di inserire i dati della carta di credito. La procedura ha solo lo scopo di verificare la validità di metodo di pagamento.</label>
            <label className='text bold'>Nessun importo verrà addebitato sulla vostra carta.</label>
            <div className='buttons-container'>
                {isBoarded ?
                    renderRemoveCard()
                    :
                    renderAllocateCard()
                }
            </div>
        </div>
    )
}

export default Payment